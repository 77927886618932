import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { QuizPerguntaCompleta } from 'src/app/models/quiz/quizPergunta';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})

export class QuizPerguntaService {
  constructor(private http: HttpClient) {}

  getQuizPerguntas():  Observable<QuizPerguntaCompleta[]> {
    return this.http.get<QuizPerguntaCompleta[]>(`${ apiUrl }/QuizPergunta/getList`);
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuizUsuario } from 'src/app/models/quiz/quizUsuario';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class QuizUsuarioService {
  constructor(private http: HttpClient) {}

  salvarQuizUsuario(quizUsuario: QuizUsuario): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    const body=JSON.stringify(quizUsuario);
    return this.http.post(`${ apiUrl }/QuizUsuario/create`, body, {'headers': headers});
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private jogadasHojeKey = 'jogadasHoje';
  private ultimaDataJogadaKey = 'ultimaDataJogada';

  constructor() { }

  // Verifica o número de jogadas feitas hoje
  getJogadasHoje(): number {
    // const jogadasHoje = localStorage.getItem(this.jogadasHojeKey);
    // const ultimaDataJogada = localStorage.getItem(this.ultimaDataJogadaKey);
    // const today = new Date().toDateString();

    // if (ultimaDataJogada === today) {
    //   return jogadasHoje ? parseInt(jogadasHoje, 10) : 0;
    // } else {
    //   // Reinicie o contador de jogadas se for um novo dia
    //   localStorage.setItem(this.ultimaDataJogadaKey, today);
    //   localStorage.setItem(this.jogadasHojeKey, '0');
    //   return 0;
    // }
    return 0;
  }

  // Atualiza o número de jogadas feitas hoje
  incrementJogadasHoje(): void {
    const jogadasHoje = this.getJogadasHoje() + 1;
    localStorage.setItem(this.jogadasHojeKey, jogadasHoje.toString());
  }
}
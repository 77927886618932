import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QuizPerguntaCompleta } from 'src/app/models/quiz/quizPergunta';
import { QuizResposta } from 'src/app/models/quiz/quizRespostas';
import { QuizResultado } from 'src/app/models/quiz/quizResultado';
import { QuizPerguntaService } from 'src/app/services/question/question.service';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  @Output() closeQuiz: EventEmitter<QuizResultado | null> = new EventEmitter();

  isLoading: boolean = false;
  perguntas: QuizPerguntaCompleta[] = [];
  perguntaAtual: QuizPerguntaCompleta | null = null;
  countdown: number = 60;
  perguntaAtualIndex = 0;
  timer: any;
  alternativaEscolhida: number | null = null;
  respostasCertas: number = 0;
  isRespostaConfirmada: boolean = false;
  quizRespostas: QuizResposta[] = [];
  quizInfoAdicionais: string[] = [];
  infoAdicionalCount: number = 0;

  quizResultado: QuizResultado | null = null;

  faQuestionCircle = faQuestionCircle;

  constructor(private quizPergunta: QuizPerguntaService, private notificacao: MatSnackBar, public router: Router) {  }

  ngOnInit(): void {
    this.carregarListaPerguntas();
  }

  get isRespostaErrada() {
    return this.alternativaEscolhida != this.perguntaAtual?.alternativaCorreta;
  }

  get botaoDesativado(): string{
    return this.isRespostaConfirmada ? "disabled-button" : "";
  }

  get perguntaAtualTitulo() {
    if (this.perguntaAtual) {
      return "(" + (this.perguntas.indexOf(this.perguntaAtual) + 1) + ") " + this.perguntaAtual?.pergunta;
    } else {
      return
    }
  }

  get fimDoQuiz() {
    return this.perguntaAtualIndex > this.totalPerguntas;
  }

  get totalPerguntas() {
    return this.perguntas.length;
  }

  carregarListaPerguntas(): void {
    this.isLoading = true;
    this.quizPergunta.getQuizPerguntas().subscribe(
      { 
        next: (result) => this.setupPerguntas(result),
        error: (err) => { 
          console.log(err);
          this.notificacao.open('Um erro inesperado ocorreu. Tente novamente mais tarde.', 
            undefined, { verticalPosition:'top', duration: 10000, panelClass: ['error-snackbar'] });
          this.isLoading = false;
          this.voltarQuiz();
        }

      });

    this.scrollParaPergunta();
  }

  setupPerguntas(perguntas: QuizPerguntaCompleta[]): void {
    this.perguntas = perguntas;
      this.quizInfoAdicionais = perguntas[0].informacoesAdicionais;
      this.quizRespostas = [];
      this.carregaPerguntaAtual();
      this.isLoading = false;
      this.iniciarContagem();
  }

  getQuizResultado(): QuizResultado {
    return new QuizResultado(this.quizRespostas, this.totalPerguntas, this.respostasCertas, true);
  }

  fecharECalcularResultados(): void {
    let resultado = this.getQuizResultado();

    if (resultado.totalPerguntas > 0) {
      this.closeQuiz.emit(this.getQuizResultado());
    }
  }

  voltarQuiz(): void {
    this.perguntas = [];
    this.closeQuiz.emit(null);
  }

  selecionarOpcao(index: number): void {
    this.alternativaEscolhida = index;
  }

  confirmarOuProximo(): void {
    if (this.isRespostaConfirmada) {
      this.proximaPergunta();
      this.isRespostaConfirmada = false;
      
      if (this.fimDoQuiz) {
        this.finalizarQuiz();
      }
    } else {
      this.validaResposta();
      clearInterval(this.timer);
      this.isRespostaConfirmada = true;
    }
  }

  finalizarQuiz(): void {
    this.validaResposta();
    clearInterval(this.timer);
    this.perguntaAtual = null;
    this.fecharECalcularResultados();
  }

  proximaPergunta(): void {
    this.infoAdicionalCount = this.infoAdicionalCount + 2;
    this.alternativaEscolhida = 0;

    this.carregaPerguntaAtual();
    clearInterval(this.timer);
    this.iniciarContagem();
  }

  validaResposta(): void {
    let isRespostaCerta = false;

    if (this.alternativaEscolhida != null && this.perguntaAtual?.alternativaCorreta == this.alternativaEscolhida) {
      this.respostasCertas++;
      isRespostaCerta = true;
    }

    if (this.perguntaAtual) {
      this.quizRespostas?.push(new QuizResposta(this.perguntaAtual?.id || null, this.alternativaEscolhida ?? 0, isRespostaCerta));
    }
  }

  carregaPerguntaAtual(): void {
    if (!this.fimDoQuiz) {
      this.perguntaAtual = this.perguntas[this.perguntaAtualIndex];
      this.countdown = 60;

      this.perguntaAtualIndex++;
    } else {
      this.perguntaAtual = null;
    }
  }

  iniciarContagem(): void {
    this.timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.confirmarOuProximo();
      }
    }, 1000);

    this.scrollParaPergunta();
  }

  get infoCardOne() {
    if (this.quizInfoAdicionais[this.infoAdicionalCount]) {
      return this.quizInfoAdicionais[this.infoAdicionalCount];
    } else {
      return;
    }
  }

  get infoCardTwo() {
    if (this.quizInfoAdicionais[this.infoAdicionalCount + 1]) {
      return this.quizInfoAdicionais[this.infoAdicionalCount + 1];
    } else {
      return;
    }
  }

  scrollParaPergunta() {
    document.getElementById("pergunta")?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuizResultado } from 'src/app/models/quiz/quizResultado';
import { QuizUsuario } from 'src/app/models/quiz/quizUsuario';
import { QuizUsuarioService } from 'src/app/services/quizUsuario/quiz-usuario.service';

@Component({
  selector: 'app-resultado',
  templateUrl: './resultado.component.html',
  styleUrls: ['./resultado.component.css']
})
export class ResultadoComponent {
  @Input() quizResultado: QuizResultado | null = null;
  @Input() quizUsuario: QuizUsuario | null = null;
  @Output() voltarEJogarNovamente: EventEmitter<any> = new EventEmitter();
  @Output() voltarESair: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  mensagemResultado: string = '';
  feedbackEnviado: boolean = false;

  constructor(public dialog: MatDialog, private quizUsuarioService: QuizUsuarioService, private notificacao: MatSnackBar) {}

  ngOnInit() {
    this.isLoading = true;
    this.setMensagemResultado();
    this.salvaQuizUsuario();
  }

  get respostasCertas(): number {
    return this.quizResultado?.respostasCertas ?? 0;
  }

  get respostasErradas(): number {
    return ((this.quizResultado?.totalPerguntas ?? 0) - (this.quizResultado?.respostasCertas ?? 0));
  }

  get pontuacaoPercentual(): number {
    return Math.round(((this.quizResultado?.respostasCertas ?? 0) / (this.quizResultado?.totalPerguntas ?? 0) * 100) ?? 0);
  }

  jogarNovamente() {
    this.voltarEJogarNovamente.emit();
  }

  sairDoQuiz() {
    this.voltarESair.emit();
  }

  setMensagemResultado() {
    if (this.quizResultado) {
      if (this.quizResultado.respostasCertas >= 0 && this.quizResultado.respostasCertas <= 3) {
        this.mensagemResultado = 'Você pode melhorar!';
      } else if (this.quizResultado.respostasCertas >= 4 && this.quizResultado.respostasCertas <= 6) {
        this.mensagemResultado = 'Bom trabalho!';
      } else {
        this.mensagemResultado = 'Excelente! Você está fazendo a diferença!';
      }
    }
  }

  salvaQuizUsuario() {
    if (this.quizUsuario != null && this.quizUsuario != undefined) {
      this.quizUsuarioService.salvarQuizUsuario(this.quizUsuario).subscribe({
          next: () => {
            this.notificacao.open('Resultado salvo com sucesso!', 
            undefined, { verticalPosition:'top', duration: 50000, panelClass: ['success-snackbar'] });
            this.isLoading = false;
          }, 
          error: () => {
            this.notificacao.open('Um erro inesperado ocorreu. Tente novamente mais tarde.', 
            undefined, { verticalPosition:'top', duration: 50000, panelClass: ['error-snackbar'] });
            this.isLoading = false;
          },
      });
    }
  }
}

<div class="resultado">
  <div class="row">
    <div class="col-md-12">
      <div class="quiz-box align-middle fade-in-up" style="margin-top: 10%;">
        <div class="row">
          <div class="col-md-12">
            <h1>Parabéns, você concluiu o quiz!</h1>
          </div>
        </div>
  
        <div class="row">
          <!-- Respostas Certas -->
          <p class="respostas-certas"> <b> Respostas certas: {{ respostasCertas }} </b></p>
          
        </div>
          <!-- Respostas Erradas -->
          <p class="respostas-erradas font-weight-bol"> <b> Respostas erradas: {{ respostasErradas }} </b></p>
          
          <!-- Pontuação -->
          <p class="text-dark font-weight-bol"> <b> Pontuação: {{ pontuacaoPercentual }}% </b></p>
  
          <!-- Mensagem de Resultado -->
          <h3 class="mt-3">{{ mensagemResultado }}</h3>
          
          <!-- Espaço em branco -->
          <div class="my-2"></div>
          
          <!-- Botão "Jogar Novamente" -->
          <button class="btn btn-dark" (click)="jogarNovamente()">Jogar Novamente</button>
          
          <!-- Espaço em branco -->
          <div class="my-2"></div>
          
          <!-- Botão "Voltar" -->
          <button class="btn btn-light" (click)="sairDoQuiz()">Voltar</button>
          
          <!-- Rodapé -->
          <p class="small mt-3">Obrigada por jogar! 😃 </p>

          <a href="https://forms.gle/isXF8d9DnAjVXEA86" target="_blank"><button class="btn btn-warning">Dê seu feedback!!!</button></a>
      </div>
    </div>
  </div> 
</div>

<app-loading *ngIf="isLoading"></app-loading>
<app-navbar></app-navbar>

<section class="parallax">
    <div class="rowfade-in-up">
        <div class="col-md-12 content-field">
            <h2>Sobre o Projeto</h2> <br/>
            <p class="text-jusify"> 
                O Seallution é uma iniciativa dedicada à conscientização e educação sobre a poluição marinha. <br/>
                <p style="text-align: justify; font-size: 1.4rem;"> Estamos comprometidos em fazer a diferença e proteger os oceanos para as gerações futuras. Junte-se a nós no Projeto Seallution e vamos trabalhar juntos para um futuro mais limpo e saudável para nossos mares. </p>
            
        </div>
    </div>
</section>

<section class="content">
    <div class="container">
        <h2>Nosso Objetivo</h2>
        <p class="text-jusify">
            Nosso objetivo é fornecer uma plataforma interativa e educativa onde os participantes possam aprender sobre os impactos da poluição nos oceanos e descobrir maneiras de tomar medidas significativas para proteger esses preciosos ecossistemas. Através de quizzes interativos e informativos, como o Seallution, buscamos engajar pessoas de todas as idades e origens em questões ambientais importantes, como a poluição marinha. Nossa abordagem inclui não apenas testar o conhecimento dos participantes, mas também oferecer informações adicionais detalhadas para aumentar a compreensão do problema e inspirar ação. <br/><br/>
            Além disso, o Projeto Seallution se esforça para promover uma cultura de conservação marinha, incentivando práticas sustentáveis e responsáveis em relação ao uso dos recursos marinhos. Queremos capacitar indivíduos a se tornarem defensores ativos dos oceanos, adotando hábitos e comportamentos que minimizem a poluição e preservem a biodiversidade marinha.
        </p>
    </div>
</section>

<section class="parallax">
    <div class="row" style="margin: auto;">
        <div class="col-md-6">
            <div class="content-field fade-in-up criadores-content">
                <h2 style="font-size: 3rem;">Helen Ferreira</h2>
                <i>Estudante de Ciências Biológicas (Bacharelado) </i>
                <br> &nbsp;
                <p>
                    Sonha na união da ciência com a tecnologia. Utiliza da educação como meio de propagar boas práticas ambientais.
                </p>
            </div>
        </div>

        <div class="col-md-6">
            <div class="content-field fade-in-up criadores-content">
                <h2 style="font-size: 3rem;">Alisson Schelbauer</h2>
                <i> Desenvolvedor de software </i>
                <br> &nbsp;
                <p>
                    Gosta de desafios e é um entusiasta da boa ciência. Procura usar da tecnologia para resolver os problemas do mundo.
                </p>
            </div>
        </div>
    </div>
</section>
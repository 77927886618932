<app-navbar></app-navbar>

<section class="parallax">
    <div class="rowfade-in-up">
        <div class="col-md-12 content-field">
            <h1>Seallution</h1>
        </div>
        <br>
        <div class="col-md-12">
            <button class="btn botao-play-quiz btn-light" routerLink="quiz">Jogar</button>
        </div>
    </div>
</section>

<section class="content">
    <div class="container">
        <h2>Sobre o Quiz</h2>
        <p class="text-jusify">
            Trata-se de um quiz interativo e educativo que aborda a questão da poluição marinha de forma abrangente. Não se limita apenas a testar seus conhecimentos, mas também proporciona uma exploração detalhada dos diversos tipos de poluição que afetam nossos oceanos.
            <br> <br>
            Durante o quiz, você terá acesso a informações adicionais relevantes, oferecendo insights valiosos sobre como a poluição marinha impacta os ecossistemas costeiros e a vida marinha. Desde os diferentes tipos de poluentes, como plásticos, poluentes químicos e resíduos orgânicos, até situações práticas que desafiam sua compreensão sobre como lidar com a poluição marinha em seu cotidiano, nosso quiz aborda uma ampla gama de tópicos.
            <br> <br>
            Além disso, oferece oportunidades para reflexão sobre suas próprias ações e como você poderia agir de maneira mais consciente em relação à poluição marinha. Nosso objetivo não é apenas testar seus conhecimentos, mas também inspirar mudanças positivas em sua relação com o ambiente marinho, incentivando práticas mais sustentáveis e responsáveis.
        </p>
    </div>
</section>

<section class="parallax">
    <div class="content-field fade-in-up">
        <h2>Sobre o Projeto</h2> <br/>
        <p class="text-jusify">
            O Seallution é uma iniciativa dedicada à conscientização e educação sobre a poluição marinha.
        </p>
        <a class="text-link" (click)="navigateTo('sobre')">Clique aqui</a> para saber mais.
    </div>
</section>
import { Component, OnInit } from '@angular/core';
import { QuizResultado } from '../models/quiz/quizResultado';
import { LocalStorageService } from '../services/local-storage.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { QuizUsuario } from '../models/quiz/quizUsuario';
import { Router } from '@angular/router';

const quantidadeJogadasPorDia: number = 3;
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
})

export class QuizComponent implements OnInit {
  startQuiz: boolean = false;
  exibirResultado: boolean = false;
  quizResultado: QuizResultado | null = null;

  quizUsuario: QuizUsuario = new QuizUsuario();

  constructor(private localStorageService: LocalStorageService, private notificacao: MatSnackBar, private router: Router) {
    this.quizResultado = null;
  }

  ngOnInit(): void {
    this.startQuiz = false;
    this.exibirResultado = false;
  }

  playQuiz(): void {
    if (!this.validaForumlarioInicial()) {
      this.startQuiz = false;
      this.exibirResultado = false;
      return;
    }

    const jogadasHoje = this.localStorageService.getJogadasHoje();

    if (jogadasHoje >= quantidadeJogadasPorDia) {
      this.notificacao.open('Você ultrapassou a quantidade máxima jogada por hoje. Tente novamente amanhã.',
       undefined, { verticalPosition:'top', duration: 10000, panelClass: ['alert-snackbar'] });

      this.startQuiz = false;
      this.exibirResultado = false;
      this.quizUsuario = new QuizUsuario();
    } else {
      this.notificacao.dismiss();
      this.quizResultado = null;
      this.exibirResultado = false;
      this.startQuiz = true;
    }
  }

  finalizarQuiz(quizResultado: QuizResultado | null): void {
    if (quizResultado && quizResultado.totalPerguntas > 0) {
      console.log("Salvando resultado...")

      this.quizResultado = quizResultado;
      this.quizUsuario.quizRespostas = quizResultado.quizRespostas;
      this.exibirResultado = quizResultado.exibirResultado;
      this.localStorageService.incrementJogadasHoje();
      this.startQuiz = false;
    } else {
      this.startQuiz = false;
      this.exibirResultado = false;
    }
  }

  voltarHome() {
    this.notificacao.dismiss();
    this.router.navigate([""]);
  }

  private validaForumlarioInicial(): boolean {
    if (this.quizUsuario.nome == null || this.quizUsuario.nome == undefined || this.quizUsuario.nome == '' || this.quizUsuario.nome.trim() == '') {
      this.notificacao.open('Você deve informar o nome.',
       undefined, { verticalPosition:'top', duration: 10000, panelClass: ['error-snackbar'] });
      return false;
    }

    if (this.quizUsuario.idade == null || this.quizUsuario.idade == undefined) {
      this.notificacao.open('Você deve informar a idade.',
       undefined, { verticalPosition:'top', duration: 10000, panelClass: ['error-snackbar'] });
      return false;
    } else if (this.quizUsuario.idade < 12 || this.quizUsuario.idade > 100){
      this.notificacao.open('A idade informada não está dentro do limite aceitável (12-100).',
       undefined, { verticalPosition:'top', duration: 10000, panelClass: ['error-snackbar'] });
      return false;
    }

    if (this.quizUsuario.curso == null || this.quizUsuario.curso == undefined) {
      this.notificacao.open('Você deve informar o curso.',
       undefined, { verticalPosition:'top', duration: 10000, panelClass: ['error-snackbar'] });
      return false;
    }

    if (this.quizUsuario.turno == null || this.quizUsuario.turno == undefined) {
      this.notificacao.open('Você deve informar o turno.',
       undefined, { verticalPosition:'top', duration: 10000, panelClass: ['error-snackbar'] });
      return false;
    }

    if (this.quizUsuario.periodo == null || this.quizUsuario.periodo == undefined) {
      this.notificacao.open('Você deve informar o período.',
       undefined, { verticalPosition:'top', duration: 10000, panelClass: ['error-snackbar'] });
      return false;
    } else if (this.quizUsuario.periodo < 1 || this.quizUsuario.periodo > 10){
      this.notificacao.open('O período informado não está dentro do limite aceitável (1-10).',
       undefined, { verticalPosition:'top', duration: 10000, panelClass: ['error-snackbar'] });
      return false;
    }

    return true;
  }
}
<div class="background-image">
  <div class="quiz-home" *ngIf="!startQuiz && !exibirResultado">
    <div class="container content-field">
      <div class="row">
        <div class="col-md-12">
          <h2>Bem-vindo ao Seallution!</h2>
          <p>Teste seu conhecimento e aprenda sobre os impactos da poluição nos oceanos. Escreva seus dados abaixo: </p>
        </div>
      </div>
      <div class="row form-inicial">
        <div class="col-md-10 ">
          <label for="nome">Nome: </label>
          <input required class="form-control" type="text" id="nome" name="nome" placeholder="Seu nome" [(ngModel)]="quizUsuario.nome">
        </div>
        <div class="col-md-2 justify-content-center">
          <label for="nome">Idade: </label>
          <input required class="form-control" type="number" step="1" min="12" max="100" maxlength="3" id="idade" name="idade" 
            placeholder="Sua idade" [(ngModel)]="quizUsuario.idade">
        </div>

        <div class="col-md-12"> &nbsp; </div>

        <div class="col-md-6">
          <label for="curso">Curso: </label>
          <select required id="curso" name="curso" class="form-control" [(ngModel)]="quizUsuario.curso">
            <option value="null" disabled> Curso </option>
            <option value="1">Ciências Biológicas</option>
            <option value="3">Pedagogia</option>
            <!--
              <option value="2">Administração</option>
              <option value="4">Nutrição</option>
              <option value="5">Psicologia</option> 
            -->
          </select>
        </div>

        <div class="col-md-4">
          <label for="turno">Turno</label>
          <select required id="turno" name="turno" class="form-control" [(ngModel)]="quizUsuario.turno">
            <option value="null" disabled> Turno </option>
            <option value="1">Manhã</option>
            <!--<option value="2">Tarde</option> -->
            <option value="3">Noite</option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="periodo">Período: </label>
          <input required type="number" step="1" min="1" max="10" id="periodo" name="periodo" class="form-control" placeholder="Período"
            [(ngModel)]="quizUsuario.periodo"/>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn-light mb-2" style="font-size: large;" (click)="playQuiz()">Iniciar</button>
        </div>
        <div class="col-md-12">
          <button type="button" class="btn btn-warning mb-2" style="font-size: medium;" (click)="voltarHome()">Sair</button>
        </div>
        &nbsp;
        <div class="col-md-12">
          <span> <i> *Ao clicar em "Iniciar" você confirma que autorizou a utilização dos dados fornecidos para pesquisa científica.</i> </span>
        </div>
      </div>
    </div>
  </div>
    <app-question *ngIf="startQuiz" (closeQuiz)="finalizarQuiz($event)"></app-question>
    <app-resultado *ngIf="exibirResultado" [quizResultado]="quizResultado" [quizUsuario]="quizUsuario" (voltarEJogarNovamente)="this.startQuiz = false; this.exibirResultado = false;" (voltarESair)="finalizarQuiz(null)"></app-resultado>
</div>
import { QuizResposta } from "./quizRespostas";

export class QuizResultado {
    quizRespostas: QuizResposta[];
    totalPerguntas: number;
    respostasCertas: number;
    exibirResultado: boolean;

    constructor(
        quizRespostas: QuizResposta[],
        totalPerguntas: number,
        respostasCertas: number,
        exibirResultado: boolean
    ) {
        this.quizRespostas = quizRespostas;
        this.totalPerguntas = totalPerguntas;
        this.respostasCertas = respostasCertas;
        this.exibirResultado = exibirResultado;
    }
}
<div class="row">
  <div class="col-md-4">
    <div class="card-info">
      <mat-accordion *ngIf="infoCardTwo">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title style="padding: 0px 45%;">
              <fa-icon [icon]="faQuestionCircle" size="2x">></fa-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [innerHTML]="infoCardTwo"> </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="col-md-4">
    <div class="quiz-box fade-in-up align-middle" *ngIf="perguntaAtual" id="pergunta">
      <h3 class="question-text text-center"> <b> {{ perguntaAtualTitulo }} </b></h3>

      <!-- Exibir o cronômetro de 30 segundos -->
      <div class="timer">
        <!-- TODO: Adicionar barra de progresso para contar o tempo estante -->
        <span>Tempo restante: <span id="timer">{{ countdown }}</span> segundos</span>
      </div>
      
      <div class="container alternatives ml-3">
        <div class="btn-group-vertical" role="group" aria-label="Alternatives">
          <button type="button" [class]="'btn btn-light mb-2 ' + botaoDesativado"
            (click)="selecionarOpcao(1)" 
            [class.active-button]="alternativaEscolhida === 1"
            [class.alternativa-errada]="isRespostaConfirmada && (isRespostaErrada && alternativaEscolhida === 1) || (isRespostaConfirmada && !alternativaEscolhida)"
            [class.alternativa-correta]="isRespostaConfirmada && perguntaAtual.alternativaCorreta === 1">
              {{ perguntaAtual.alternativaA }}
          </button>
          <button type="button" [class]="'btn btn-light mb-2 ' + botaoDesativado"
            (click)="selecionarOpcao(2)"
            [class.active-button]="alternativaEscolhida === 2"
            [class.alternativa-errada]="isRespostaConfirmada && (isRespostaErrada && alternativaEscolhida === 2) || (isRespostaConfirmada && !alternativaEscolhida)"
            [class.alternativa-correta]="isRespostaConfirmada && perguntaAtual.alternativaCorreta === 2">
              {{ perguntaAtual.alternativaB }}
          </button>
          <button type="button" [class]="'btn btn-light mb-2 ' + botaoDesativado"
            (click)="selecionarOpcao(3)"
            [class.active-button]="alternativaEscolhida === 3"
            [class.alternativa-errada]="isRespostaConfirmada && (isRespostaErrada && alternativaEscolhida === 3) || (isRespostaConfirmada && !alternativaEscolhida)"
            [class.alternativa-correta]="isRespostaConfirmada && perguntaAtual.alternativaCorreta === 3">
              {{ perguntaAtual.alternativaC }}
          </button>
          <button type="button" [class]="'btn btn-light mb-2 ' + botaoDesativado"
            (click)="selecionarOpcao(4)"
            [class.active-button]="alternativaEscolhida === 4"
            [class.alternativa-errada]="isRespostaConfirmada && (isRespostaErrada && alternativaEscolhida === 4) || (isRespostaConfirmada && !alternativaEscolhida)"
            [class.alternativa-correta]="isRespostaConfirmada && perguntaAtual.alternativaCorreta === 4">
              {{ perguntaAtual.alternativaD }}
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-center">
          <button class="btn btn-dark mt-3" (click)="confirmarOuProximo()">
            {{ isRespostaConfirmada ? 'Próxima' : 'Confirmar' }}
          </button>
        </div>
        <div class="col-md-12 text-center">
          <button *ngIf="!isLoading" class="btn btn-danger mt-3" (click)="voltarQuiz()"> Sair </button>
        </div>
      </div>
    </div>

  </div>

  <div class="col-md-4">

    <div class="card-info">
      <mat-accordion *ngIf="infoCardOne">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title style="padding: 0px 45%;">
              <fa-icon [icon]="faQuestionCircle" size="2x">></fa-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [innerHTML]="infoCardOne"> </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    
    <!-- <mat-card *ngIf="infoCardOne" class="card-info">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title></mat-card-title>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        {{infoCardOne}}
      </mat-card-content>
    </mat-card> -->

  </div>

</div>
<app-loading *ngIf="isLoading"></app-loading>
import { Curso } from "./cursoEnum";
import { QuizResposta } from "./quizRespostas";
import { Turno } from "./turnoEnum";

export class QuizUsuario {
    constructor(
      public nome: string | null = null,
      public idade: number | null = null,
      public curso: Curso | null = null,
      public turno: Turno | null = null,
      public periodo: number | null = null,
      public quizRespostas: QuizResposta[] = []
    ) {}
  }